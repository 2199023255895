@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

/* Base styles scoped to sb3-contain */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sb3-contain code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.sb3-contain .container {
    @apply sb3-max-w-7xl sb3-m-auto sb3-py-3
}

.sb3-contain .sb3-content {
    @apply sb3-max-w-5xl sb3-m-auto sb3-pb-3;
}

.sb3-contain .skeleton {
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.sb3-contain .skeleton.text {
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.sb3-contain .skeleton.text50 {
    width: 50%;
    height: 10px;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.sb3-contain .skeleton.text25 {
    width: 25%;
    height: 10px;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.sb3-contain .skeleton.title {
    width: 100%;
    height: 20px;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.sb3-contain .skeleton.title25 {
    width: 25%;
    height: 20px;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.sb3-contain .skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.sb3-contain .skeleton.thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 2%;
}

.sb3-contain .skeleton.thumbnail300 {
    width: 200px;
    height: 200px;
    border-radius: 2%;
}

.sb3-contain select option:disabled {
    color: #000;
    background-color: #EBEBE4;
}

/* Side menu styles */
.sb3-contain .side-menu .group {
    @apply sb3-space-y-0.5;
}

.sb3-contain .side-menu .group li {
    @apply last:sb3-border-b-0 !sb3-text-[14px]
}

.sb3-contain .side-menu .group .link {
    @apply sb3-w-full sb3-text-left sb3-pl-1 sb3-pr-3 sb3-text-[#172B4D] sb3-flex sb3-justify-between sb3-items-center;
}

.sb3-contain .side-menu .group .link .title-group {
    @apply sb3-border sb3-border-light hover:sb3-bg-gray-200;
}

.sb3-contain .side-menu .group .link.active .title-group {
    @apply sb3-border sb3-border-primary;
}

.sb3-contain .mq-editable-field {
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-editable-field .mq-cursor {
    border-left: 1px solid black;
    margin-left: -1px;
    position: relative;
    z-index: 1;
    padding: 0;
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-editable-field .mq-cursor.mq-blink {
    visibility: hidden;
  }
  
  .sb3-contain .mq-editable-field,
  .sb3-contain .mq-math-mode .mq-editable-field {
    border: 1px solid gray;
  }
  
  .sb3-contain .mq-editable-field.mq-focused,
  .sb3-contain .mq-math-mode .mq-editable-field.mq-focused {
    -webkit-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
    -moz-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
    box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
    border-color: #709AC0;
    border-radius: 1px;
  }
  
  .sb3-contain .mq-math-mode .mq-editable-field {
    margin: 1px;
  }
  
  .sb3-contain .mq-editable-field .mq-latex-command-input {
    color: inherit;
    font-family: "Courier New", monospace;
    border: 1px solid gray;
    padding-right: 1px;
    margin-right: 1px;
    margin-left: 2px;
  }
  
  .sb3-contain .mq-editable-field .mq-latex-command-input.mq-empty {
    background: transparent;
  }
  
  .sb3-contain .mq-editable-field .mq-latex-command-input.mq-hasCursor {
    border-color: ActiveBorder;
  }
  
  .sb3-contain .mq-editable-field.mq-empty:after,
  .sb3-contain .mq-editable-field.mq-text-mode:after,
  .sb3-contain .mq-math-mode .mq-empty:after {
    visibility: hidden;
    content: 'c';
  }
  
  .sb3-contain .mq-editable-field .mq-cursor:only-child:after,
  .sb3-contain .mq-editable-field .mq-textarea + .mq-cursor:last-child:after {
    visibility: hidden;
    content: 'c';
  }
  
  .sb3-contain .mq-editable-field .mq-text-mode .mq-cursor:only-child:after {
    content: '';
  }
  
  .sb3-contain .mq-editable-field.mq-text-mode {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .sb3-contain .mq-root-block,
  .sb3-contain .mq-math-mode .mq-root-block {
    display: -moz-inline-box;
    display: inline-block;
    width: 100%;
    padding: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
  
  .sb3-contain .mq-math-mode {
    font-variant: normal;
    font-weight: normal;
    font-style: normal;
    font-size: 115%;
    line-height: 1;
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-math-mode .mq-non-leaf,
  .sb3-contain .mq-math-mode .mq-scaled {
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-math-mode var,
  .sb3-contain .mq-math-mode .mq-text-mode,
  .sb3-contain .mq-math-mode .mq-nonSymbola {
    font-family: "Times New Roman", Symbola, serif;
    line-height: 0.9;
  }
  
  .sb3-contain .mq-math-mode * {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    border-color: black;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;
  }
  
  .sb3-contain .mq-math-mode .mq-empty {
    background: #ccc;
  }
  
  .sb3-contain .mq-math-mode .mq-empty.mq-root-block {
    background: transparent;
  }
  
  .sb3-contain .mq-math-mode.mq-empty {
    background: transparent;
  }
  
  .sb3-contain .mq-math-mode .mq-text-mode {
    display: inline-block;
    white-space: pre;
  }
  
  .sb3-contain .mq-math-mode .mq-text-mode.mq-hasCursor {
    box-shadow: inset darkgray 0 0.1em 0.2em;
    padding: 0 0.1em;
    margin: 0 -0.1em;
    min-width: 1ex;
  }
  
  .sb3-contain .mq-math-mode .mq-font {
    font: 1em "Times New Roman", Symbola, serif;
  }
  
  .sb3-contain .mq-math-mode .mq-font * {
    font-family: inherit;
    font-style: inherit;
  }
  
  .sb3-contain .mq-math-mode b,
  .sb3-contain .mq-math-mode b.mq-font {
    font-weight: bolder;
  }
  
  .sb3-contain .mq-math-mode var,
  .sb3-contain .mq-math-mode i,
  .sb3-contain .mq-math-mode i.mq-font {
    font-style: italic;
  }
  
  .sb3-contain .mq-math-mode var.mq-f {
    margin-right: 0.2em;
    margin-left: 0.1em;
  }
  
  .sb3-contain .mq-math-mode .mq-roman var.mq-f {
    margin: 0;
  }
  
  .sb3-contain .mq-math-mode big {
    font-size: 200%;
  }
  
  .sb3-contain .mq-math-mode .mq-int > big {
    display: inline-block;
    -webkit-transform: scaleX(0.7);
    -moz-transform: scaleX(0.7);
    -ms-transform: scaleX(0.7);
    -o-transform: scaleX(0.7);
    transform: scaleX(0.7);
    vertical-align: -0.16em;
  }
  
  .sb3-contain .mq-math-mode .mq-int > .mq-supsub {
    font-size: 80%;
    vertical-align: -1.1em;
    padding-right: 0.2em;
  }
  
  .sb3-contain .mq-math-mode .mq-int > .mq-supsub > .mq-sup > .mq-sup-inner {
    vertical-align: 1.3em;
  }
  
  .sb3-contain .mq-math-mode .mq-int > .mq-supsub > .mq-sub {
    margin-left: -0.35em;
  }
  
  .sb3-contain .mq-math-mode .mq-roman {
    font-style: normal;
  }
  
  .sb3-contain .mq-math-mode .mq-sans-serif {
    font-family: sans-serif, Symbola, serif;
  }
  
  .sb3-contain .mq-math-mode .mq-monospace {
    font-family: monospace, Symbola, serif;
  }
  
  .sb3-contain .mq-math-mode .mq-overline {
    border-top: 1px solid black;
    margin-top: 1px;
  }
  
  .sb3-contain .mq-math-mode .mq-underline {
    border-bottom: 1px solid black;
    margin-bottom: 1px;
  }
  
  .sb3-contain .mq-math-mode .mq-binary-operator {
    padding: 0 0.2em;
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub {
    text-align: left;
    font-size: 90%;
    vertical-align: -0.5em;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub.mq-sup-only {
    vertical-align: 0.5em;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub.mq-sup-only .mq-sup {
    display: inline-block;
    vertical-align: text-bottom;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub .mq-sup {
    display: block;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub .mq-sub {
    display: block;
    float: left;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub .mq-binary-operator {
    padding: 0 0.1em;
  }
  
  .sb3-contain .mq-math-mode .mq-supsub .mq-fraction {
    font-size: 70%;
  }
  
  .sb3-contain .mq-math-mode sup.mq-nthroot {
    font-size: 80%;
    vertical-align: 0.8em;
    margin-right: -0.6em;
    margin-left: 0.2em;
    min-width: 0.5em;
  }
  
  .sb3-contain .mq-math-mode .mq-paren {
    padding: 0 0.1em;
    vertical-align: top;
    -webkit-transform-origin: center 0.06em;
    -moz-transform-origin: center 0.06em;
    -ms-transform-origin: center 0.06em;
    -o-transform-origin: center 0.06em;
    transform-origin: center 0.06em;
  }
  
  .sb3-contain .mq-math-mode .mq-paren.mq-ghost {
    color: silver;
  }
  
  .sb3-contain .mq-math-mode .mq-paren + span {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
  }
  
  .sb3-contain .mq-math-mode .mq-array {
    vertical-align: middle;
    text-align: center;
  }
  
  .sb3-contain .mq-math-mode .mq-array > span {
    display: block;
  }
  
  .sb3-contain .mq-math-mode .mq-operator-name {
    font-family: Symbola, "Times New Roman", serif;
    line-height: 0.9;
    font-style: normal;
  }
  
  .sb3-contain .mq-math-mode var.mq-operator-name.mq-first {
    padding-left: 0.2em;
  }
  
  .sb3-contain .mq-math-mode var.mq-operator-name.mq-last,
  .sb3-contain .mq-math-mode .mq-supsub.mq-after-operator-name {
    padding-right: 0.2em;
  }
  
  .sb3-contain .mq-math-mode .mq-fraction {
    font-size: 90%;
    text-align: center;
    vertical-align: -0.4em;
    padding: 0 0.2em;
  }
  
  .sb3-contain .mq-math-mode .mq-fraction,
  .sb3-contain .mq-math-mode .mq-large-operator,
  .sb3-contain .mq-math-mode x:-moz-any-link {
    display: -moz-groupbox;
  }
  
  .sb3-contain .mq-math-mode .mq-fraction,
  .sb3-contain .mq-math-mode .mq-large-operator,
  .sb3-contain .mq-math-mode x:-moz-any-link,
  .sb3-contain .mq-math-mode x:default {
    display: inline-block;
  }
  
  .sb3-contain .mq-math-mode .mq-numerator,
  .sb3-contain .mq-math-mode .mq-denominator,
  .sb3-contain .mq-math-mode .mq-dot-recurring {
    display: block;
  }
  
  .sb3-contain .mq-math-mode .mq-numerator {
    padding: 0 0.1em;
  }
  
  .sb3-contain .mq-math-mode .mq-denominator {
    border-top: 1px solid;
    float: right;
    width: 100%;
    padding: 0.1em;
  }
  
  .sb3-contain .mq-math-mode .mq-selection,
  .sb3-contain .mq-editable-field .mq-selection,
  .sb3-contain .mq-math-mode .mq-selection .mq-non-leaf,
  .sb3-contain .mq-editable-field .mq-selection .mq-non-leaf,
  .sb3-contain .mq-math-mode .mq-selection .mq-scaled,
  .sb3-contain .mq-editable-field .mq-selection .mq-scaled {
    background: #B4D5FE !important;
    background: Highlight !important;
    color: HighlightText;
    border-color: HighlightText;
  }
  
  .sb3-contain .mq-math-mode .mq-selection .mq-matrixed,
  .sb3-contain .mq-editable-field .mq-selection .mq-matrixed {
    background: #39F !important;
  }
  
  .sb3-contain .mq-math-mode .mq-selection .mq-matrixed-container,
  .sb3-contain .mq-editable-field .mq-selection .mq-matrixed-container {
    filter: progid:DXImageTransform.Microsoft.Chroma(color='#3399FF') !important;
  }
  
  .sb3-contain .mq-editable-field .mq-textarea,
  .sb3-contain .mq-math-mode .mq-textarea {
    position: relative;
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
  }
  
  .sb3-contain .mq-math-mode .mq-dot-recurring {
    text-align: center;
    height: 0.3em;
  }
  
  .sb3-contain .mq-math-mode .mq-sqrt-prefix {
    padding-top: 0;
    position: relative;
    top: 0.1em;
    vertical-align: top;
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
  }
  
  .sb3-contain .mq-math-mode .mq-sqrt-stem {
    border-top: 1px solid;
    margin-top: 1px;
    padding-left: 0.15em;
    padding-right: 0.2em;
    margin-right: 0.1em;
    padding-top: 1px;
  }
  
  .sb3-contain .mq-math-mode .mq-diacritic-above {
    display: block;
    text-align: center;
    line-height: 0.4em;
  }
  
  .sb3-contain .mq-math-mode .mq-diacritic-stem {
    display: block;
    text-align: center;
  }
  
  .sb3-contain .mq-math-mode .mq-hat-prefix {
    display: block;
    text-align: center;
    line-height: 0.95em;
    margin-bottom: -0.7em;
    transform: scaleX(1.5);
    -moz-transform: scaleX(1.5);
    -o-transform: scaleX(1.5);
    -webkit-transform: scaleX(1.5);
  }
  
  .sb3-contain .mq-math-mode .mq-hat-stem {
    display: block;
  }
  
  .sb3-contain .mq-math-mode .mq-large-operator {
    vertical-align: -0.2em;
    padding: 0.2em;
    text-align: center;
  }
  
  .sb3-contain .mq-math-mode .mq-large-operator .mq-from,
  .sb3-contain .mq-math-mode .mq-large-operator big,
  .sb3-contain .mq-math-mode .mq-large-operator .mq-to {
    display: block;
  }
  
  .sb3-contain .mq-math-mode .mq-large-operator .mq-from,
  .sb3-contain .mq-math-mode .mq-large-operator .mq-to {
    font-size: 80%;
  }
  
  .sb3-contain .mq-math-mode .mq-large-operator .mq-from {
    float: right;
    width: 100%;
  }
  
  .sb3-contain .mq-math-mode,
  .sb3-contain .mq-math-mode .mq-editable-field {
    cursor: text;
    font-family: Symbola, "Times New Roman", serif;
  }
  
  .sb3-contain .mq-math-mode .mq-overarc {
    border-top: 1px solid black;
    -webkit-border-top-right-radius: 50% 0.3em;
    -moz-border-radius-topright: 50% 0.3em;
    border-top-right-radius: 50% 0.3em;
    -webkit-border-top-left-radius: 50% 0.3em;
    -moz-border-radius-topleft: 50% 0.3em;
    border-top-left-radius: 50% 0.3em;
    margin-top: 1px;
    padding-top: 0.15em;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow {
    min-width: 0.5em;
    border-top: 1px solid black;
    margin-top: 1px;
    padding-top: 0.2em;
    text-align: center;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow:before {
    display: block;
    position: relative;
    top: -0.34em;
    font-size: 0.5em;
    line-height: 0em;
    content: '\27A4';
    text-align: right;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-left:before {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-both {
    vertical-align: text-bottom;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-both.mq-empty {
    min-height: 1.23em;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-both.mq-empty:after {
    top: -0.34em;
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-both:before {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  
  .sb3-contain .mq-math-mode .mq-overarrow.mq-arrow-both:after {
    display: block;
    position: relative;
    top: -2.3em;
    font-size: 0.5em;
    line-height: 0em;
    content: '\27A4';
    visibility: visible;
    text-align: right;
  }

  .sb3-contain .mq-math-mode .mq-selection,
.sb3-contain .mq-editable-field .mq-selection,
.sb3-contain .mq-math-mode .mq-selection .mq-non-leaf,
.sb3-contain .mq-editable-field .mq-selection .mq-non-leaf,
.sb3-contain .mq-math-mode .mq-selection .mq-scaled,
.sb3-contain .mq-editable-field .mq-selection .mq-scaled {
  background: #B4D5FE !important;
  background: Highlight !important;
  color: HighlightText;
  border-color: HighlightText;
}
.sb3-contain .mq-math-mode .mq-selection .mq-matrixed,
.sb3-contain .mq-editable-field .mq-selection .mq-matrixed {
  background: #39F !important;
}
.sb3-contain .mq-math-mode .mq-selection .mq-matrixed-container,
.sb3-contain .mq-editable-field .mq-selection .mq-matrixed-container {
  filter: progid:DXImageTransform.Microsoft.Chroma(color='#3399FF') !important;
}
  
  .sb3-contain .mq-math-mode .mq-selection.mq-blur,
  .sb3-contain .mq-editable-field .mq-selection.mq-blur,
  .sb3-contain .mq-math-mode .mq-selection.mq-blur .mq-non-leaf,
  .sb3-contain .mq-editable-field .mq-selection.mq-blur .mq-non-leaf,
  .sb3-contain .mq-math-mode .mq-selection.mq-blur .mq-scaled,
  .sb3-contain .mq-editable-field .mq-selection.mq-blur .mq-scaled,
  .sb3-contain .mq-math-mode .mq-selection.mq-blur .mq-matrixed,
  .sb3-contain .mq-editable-field .mq-selection.mq-blur .mq-matrixed {
    background: #D4D4D4 !important;
    color: black;
    border-color: black;
  }
  
  .sb3-contain .mq-math-mode .mq-selection.mq-blur .mq-matrixed-container,
  .sb3-contain .mq-editable-field .mq-selection.mq-blur .mq-matrixed-container {
    filter: progid:DXImageTransform.Microsoft.Chroma(color='#D4D4D4') !important;
  }
 .sb3-contain  .mq-editable-field .mq-textarea,
.sb3-contain .mq-math-mode .mq-textarea {
  position: relative;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}
  .sb3-contain .mq-editable-field .mq-textarea *,
  .sb3-contain .mq-math-mode .mq-textarea *,
  .sb3-contain .mq-editable-field .mq-selectable,
  .sb3-contain .mq-math-mode .mq-selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
    position: absolute;
    clip: rect(1em 1em 1em 1em);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    resize: none;
    width: 1px;
    height: 1px;
    box-sizing: content-box;
  }
  
  .sb3-contain .mq-math-mode .mq-matrixed {
    background: white;
    display: -moz-inline-box;
    display: inline-block;
  }
  
  .sb3-contain .mq-math-mode .mq-matrixed-container {
    filter: progid:DXImageTransform.Microsoft.Chroma(color='white');
    margin-top: -0.1em;
  }


/* ... (rest of MathQuill styles with .sb3-contain prefix) ... */

.sb3-contain h1 {
    @apply sb3-text-4xl sb3-font-bold sb3-m-0;
}

.sb3-contain h2 {
    @apply sb3-text-2xl sb3-font-bold sb3-m-0;
}

.sb3-contain h3 {
    @apply sb3-text-xl sb3-font-semibold sb3-normal-case sb3-m-0;
}

.sb3-contain h4 {
    @apply sb3-m-0;
}

.sb3-contain h5 {
    @apply sb3-m-0;
}

.sb3-contain h6 {
    @apply sb3-m-0;
}

/* Special styles for Compatio */
.sb3-contain .compatio-compatio .builoutbutton-right .action.primary,
.sb3-contain .galco-compatio .builoutbutton-right .action.primary {
    background: linear-gradient(180deg,#e4e4e4,#e4e4e4),linear-gradient(180deg,#f8f6f6,#d7d7d8);
    color: #7faadb;
    background: linear-gradient(0deg,#e4e4e4 17%,#fff);
    border: 2px solid #e4e4e4;
    font-size: 20px;
    outline: none;
}

.sb3-contain .custom-checkbox:checked + .checkmark {
    background-color: #9ca3af;
}

.sb3-contain * {
    letter-spacing: normal !important;
    text-transform: none;
}

/* Info text styles */
.sb3-contain .info-text ul,
.sb3-contain .info-text ul li {
    list-style-type: disc;
    list-style: disc;
    padding: revert !important;
}

.sb3-contain .info-text ol,
.sb3-contain .info-text ol li {
    list-style-type: decimal;
    list-style: decimal;
    padding: revert !important;
}

.sb3-contain .info-text a {
    color: blue;
}

.sb3-contain .info-text blockquote {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    font-style: italic;
}

.sb3-contain .info-text blockquote > p {
    display: inline;
}

.sb3-contain .info-text br {
    display: block;
    margin: 5px 0;
    content: " ";
    line-height: 5px;
}

.sb3-contain .info-text textarea,
.sb3-contain .info-text button {
    display: none;
}

.sb3-contain label {
    @apply sb3-m-0;
}

.sb3-contain p {
    @apply sb3-m-0;
}